<template>
  <div id="app">
    <div class="logo-wrap">
      <div class="logo">
        <!-- <img src="@/assets/logo.png" alt="logo"> -->
        <img src="@/assets/logo.svg" alt="logo">
        <img class="fr" src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC%E7%AB%AF%E5%AE%98%E7%BD%91/mascot.gif" alt="mascot">
      </div>
    </div>
    <div v-if="isEn" class="nav-wrap is-en">
      <div id="nav">
        <ul :class="cur=='sy'&&'cur'">
          <router-link tag="li" to="/">Homepage</router-link>
        </ul>
        <div class="v-line"></div>
        <ul :class="cur=='zggk'&&'cur'">
          <router-link tag="li" to="/exhibition">Exhibition Overview</router-link>
        </ul>
        <div class="v-line"></div>
        <ul :class="cur=='hylt'&&'cur'">
          <router-link tag="li" to="/main-forum">Conference</router-link>
          <!-- <div class="sub">
            <router-link tag="ul" to="/subject-forum">主题论坛</router-link>
            <div class="v-line"></div>
            <router-link tag="ul" to="/sub-forum">平行会议</router-link>
            <div class="v-line"></div>
            <router-link tag="ul" to="/main-forum">主旨论坛</router-link>
          </div> -->
        </ul>
        <div class="v-line"></div>
        <ul :class="cur=='tqhd'&&'cur'">
          <router-link tag="li" to="/activity">Side Events</router-link>
        </ul>
        <div class="v-line"></div>
        <ul :class="cur=='xwzx'&&'cur'">
          <li>News Center</li>
          <div class="sub">
            <router-link tag="ul" to="/sponsor">展商风采</router-link>
            <div class="v-line"></div>
            <router-link tag="ul" to="/images">图片</router-link>
            <div class="v-line"></div>
            <router-link tag="ul" to="/post">公告</router-link>
            <div class="v-line"></div>
            <router-link tag="ul" to="/news">动态</router-link>
          </div>
        </ul>
        <div class="v-line"></div>
        <ul :class="cur=='wjhg'&&'cur'">
          <li>Previous Highlights</li>
          <div class="sub">
            <!-- <router-link tag="ul" to="/catalog">展商目录</router-link>
            <div class="v-line"></div> -->
            <router-link tag="ul" to="/fifth">往届回顾</router-link>
          </div>
        </ul>
        <div class="v-line"></div>
        <ul :class="cur=='zhfw'&&'cur'">
          <li>Exhibition Services</li>
          <div class="sub" style="left: 540px">
            <router-link tag="ul" to="/files">资料下载</router-link>
            <div class="v-line"></div>
            <router-link tag="ul" to="/contact-us">联系我们</router-link>
            <div class="v-line"></div>
            <router-link tag="ul" to="/route">交通指引</router-link>
            <div class="v-line"></div>
            <router-link tag="ul" to="/hotel">合作酒店</router-link>
            <!-- <div class="v-line"></div>
            <router-link tag="ul" to="/exhibitor-sign">展商服务</router-link>
            <ul @click="full()">展商服务</ul>
            <div class="v-line"></div>
            <router-link tag="ul" to="/sign/1">观众服务</router-link> -->
          </div>
        </ul>
        <div class="v-line"></div>
        <ul :class="cur=='en'&&'cur'">
          <router-link tag="li" to="/">Chinese</router-link>
        </ul>
      </div>
    </div>
    <div v-else class="nav-wrap">
      <div id="nav">
        <ul :class="cur=='sy'&&'cur'">
          <router-link tag="li" to="/">首页</router-link>
        </ul>
        <div class="v-line"></div>
        <ul :class="cur=='zggk'&&'cur'">
          <router-link tag="li" to="/exhibition">展馆概况</router-link>
        </ul>
        <div class="v-line"></div>
        <ul :class="cur=='hylt'&&'cur'">
          <router-link tag="li" to="/main-forum">会议论坛</router-link>
          <!-- <div class="sub">
            <router-link tag="ul" to="/subject-forum">主题论坛</router-link>
            <div class="v-line"></div>
            <router-link tag="ul" to="/sub-forum">平行会议</router-link>
            <div class="v-line"></div>
            <router-link tag="ul" to="/main-forum">主旨论坛</router-link>
          </div> -->
        </ul>
        <div class="v-line"></div>
        <ul :class="cur=='tqhd'&&'cur'">
          <router-link tag="li" to="/activity">配套活动</router-link>
        </ul>
        <div class="v-line"></div>
        <ul :class="cur=='xwzx'&&'cur'">
          <li>新闻中心</li>
          <div class="sub">
            <router-link tag="ul" to="/sponsor">展商风采</router-link>
            <div class="v-line"></div>
            <router-link tag="ul" to="/images">图片</router-link>
            <div class="v-line"></div>
            <router-link tag="ul" to="/post">公告</router-link>
            <div class="v-line"></div>
            <router-link tag="ul" to="/news">动态</router-link>
          </div>
        </ul>
        <div class="v-line"></div>
        <ul :class="cur=='wjhg'&&'cur'">
          <router-link tag="li" to="/fifth">往届回顾</router-link>
        </ul>
        <!-- <ul :class="cur=='wjhg'&&'cur'">
          <li>往届回顾</li>
          <div class="sub">
            <router-link tag="ul" to="/catalog">展商目录</router-link>
            <div class="v-line"></div>
            <router-link tag="ul" to="/fourth">往届回顾</router-link>
          </div>
        </ul> -->
        <div class="v-line"></div>
        <ul :class="cur=='zhfw'&&'cur'">
          <li>展会服务</li>
          <div class="sub" style="left: 540px">
            <router-link tag="ul" to="/files">资料下载</router-link>
            <div class="v-line"></div>
            <router-link tag="ul" to="/contact-us">联系我们</router-link>
            <div class="v-line"></div>
            <router-link tag="ul" to="/route">交通指引</router-link>
            <div class="v-line"></div>
            <router-link tag="ul" to="/hotel">合作酒店</router-link>
            <!-- <div class="v-line"></div>
            <router-link tag="ul" to="/exhibitor-sign">展商服务</router-link>
            <ul @click="full()">展商服务</ul>
            <div class="v-line"></div>
            <router-link tag="ul" to="/sign/1">观众服务</router-link> -->
          </div>
        </ul>
        <div class="v-line"></div>
        <ul :class="cur=='en'&&'cur'">
          <router-link tag="li" to="/en">English</router-link>
        </ul>
      </div>
    </div>
    <router-view/>
    <footer>
      <div class="flex support-info center">
        <div class="col" style="line-height: 27px;width: 260px">
          <h3>主办单位</h3>
          <p>浙江省交通运输厅</p>
          <p>浙江省发展和改革委员会</p>
          <p>浙江省经济和信息化厅</p>
          <p>杭州市萧山区人民政府</p>
        </div>
        <div class="col" style="line-height: 25px;width: 300px">
          <h3>协办单位</h3>
          <p>浙江省交通投资集团有限公司</p>
          <p>浙江省机场集团有限公司</p>
          <p>浙江省海港投资运营集团有限公司</p>
          <p>浙江广播电视集团</p>
          <p>浙江省邮政管理局</p>
          <p>杭州市萧山经济技术开发区管委会</p>
          <p>杭州萧山交通投资集团有限公司</p>
          <h3>特邀支持单位</h3>
          <p>中国国际可持续交通创新<br>和知识中心</p>
        </div>
        <div class="col" style="line-height: 23px;width: 260px">
          <h3>支持单位</h3>
          <p>中国交通运输协会</p>
          <p>中国公路学会</p>
          <p>中国物流与采购联合会</p>
          <p>中国宇航学会</p>
          <p>中国铁道学会</p>
          <p>中国城市轨道交通协会</p>
          <p>交通运输部科学研究院</p>
          <p>交通运输部公路科学研究院</p>
          <p>上海市航空学会</p>
          <p>浙江省发展规划研究院</p>
          <p>浙江数智交院科技股份有限公司</p>
          <p>浙江省中交未来交通研究院</p>
        </div>
        <div class="col" style="line-height: 27px;width: 260px">
          <h3>承办单位</h3>
          <p>杭州中交文化发展有限公司</p>
        </div>
      </div>
      <div class="icp center">
        Copyright©2019-2020 浙江国际智慧交通产业博览会组委会 All Rights Reserved
        <p class="fr">网站备案号：<a style="color: #FFF" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">浙ICP备18001235号</a></p>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cur: '',
    }
  },
  computed: {
    isEn() {
      return this.$route.name === 'enHome'
    }
  },
  created() {
    this.$http.put('/sys/statistics', {
      id: 1
    }).then(() => {})
  },
  mounted() {
    this.$router.afterEach((to) => {
      this.cur = to.meta.cur
      window.scrollTo(0, 0)
    })
  },
  methods: {
    full() {
      this.$Modal.confirm({
        title: '名额已满',
        content: '<p>参展商报名已满！</p>',
        onOk: () => {
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.logo-wrap {
  font-size: 16px;
  height: 107px;
  width: 100%;
  min-width: 1080px;
  background: #FFF;
  .logo {
    margin: 0 auto;
    width: 1080px;
    img {
      margin-top: 17px;
      height: 70px;
      width: auto;
    }
  }
}
.nav-wrap {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  font-size: 16px;
  height: 38px;
  z-index: 1000;
  width: 100%;
  min-width: 1080px;
  background: linear-gradient(#1A85F3, #1B2295);
  #nav {
    position: relative;
    margin: 0 auto;
    height: 38px;
    width: 1080px;
    .v-line {
      float: left;
      height: 14px;
      width: 1px;
      margin: 12px 0;
      background: #FFF;
    }
    ul {
      cursor: pointer;
      float: left;
      width: 134px;
      li {
        line-height: 38px;
        color: #FFF;
        text-align: center;
      }
      .sub {
        font-weight: normal;
        height: 38px;
        display: none;
        z-index: 100; // 很有意思，hover在没有加index时IE中hover只对文字所处的位置有效
        position: absolute;
        top: 38px;
        background: #E0F1FF;
        color: #0066DD;
        ul {
          float: right;
          line-height: 38px;
          text-align: center;
        }
        ul:hover {
          background: rgba(74, 145, 226, 0.15);
          font-weight: bolder;
        }
        .v-line {
          float: right;
          background: #0066DD !important;
        }
      }
      .sub:hover {
        display: block;
      }
    }
    ul:hover {
      background: linear-gradient(#096DD5, #08108D);
      font-weight: bolder;
      div {
        display: block;
      }
    }
    .cur {
      background: linear-gradient(#096DD5, #08108D);
      font-weight: bolder;
    }
  }
}
.is-en {
  #nav {
    width: 1280px;
    ul {
      width: 159px;
    }
  }
}
#app {
  min-width: 1280px;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  background: #FFF;
}
footer {
  padding-top: 10px;
  background: #0769CC;
  .contact-us {
    height: 165px;
    .qrcode-wrap {
      height: 150px;
      width: 400px;
      justify-content: space-around;
      .item {
        float: left;
        width: 80px;
      }
      .title {
        font-size: 13px;
        color: #FFF;
        line-height: 35px;
        font-weight: 400;
        img {
          position: relative;
          top: 5px;
          width: 25px;
        }
      }
      .code {
        width: 80px;
        height: 80px;
        background: #DDDCEE;
        border-radius: 3px;
        padding: 5px;
        img {
          width: 70px;
        }
      }
    }
    .contact {
      .fr {
        padding: 10px 30px;
        height: 165px;
        width: 240px;
        h3 {
          color: #FFF;
          font-size: 18px;
          line-height: 40px;
        }
        p {
          color: #9EB8E9;
          font-size: 16px;
          font-weight: 500;
          line-height: 30px;
          img {
            width: 24px;
            margin-right: 5px;
            position: relative;
            top: 4px;
          }
        }
      }
    }
  }
  .support-info {
    justify-content: space-around;
    align-items: flex-start;
    // height: 300px;
    .col {
      color: #FFF;
      // height: 270px;
      padding: 10px 0;
      width: 290px;
      h3 {
        font-size: 16px;
        font-weight: 500;
        line-height: 50px;
        letter-spacing: 2px;
      }
      p {
        font-size: 15px;
        font-weight: 400;
        color: #BBD2FF;
      }
    }
  }
  .icp {
    font-size: 13px;
    height: 51px;
    line-height: 50px;
    color: #FFF;
    border-top: 1px solid #4A90E2;
  }
}
</style>
